/* GENERAL STYLING */

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300&family=Noto+Sans:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --swiper-theme-color: rgba(97, 113, 110, 1) !important;
}
.auth-bg {
  /* background: linear-gradient(69.57deg, #b58ff7 1.06%, #8fc4fd 52.18%, #6ff4c7 96.34%); */
}

.admin-bg {
  background: linear-gradient(86.11deg, #cb75f9 0%, #00ffc0 100%);
}

.base-color {
  background: linear-gradient(180deg, #d3ffe980 0%, #aaa0f280 100%);
}

body,
html {
  height: 100%;
}

.content {
  max-height: calc(100% - 120px);
  height: calc(100% - 120px);
}

.ReactCrop > .ReactCrop__crop-selection {
  border: 4px solid #54f49e;
}

@font-face {
  font-family: 'Hiragino';
  src: local('Hiragino'), url(../pages/home/Hiragino.otf) format('opentype');
}

@font-face {
  font-family: 'RooneySans';
  src: local('RooneySans'), url(../assets/fonts/RooneySans/RooneySansRegular.OTF) format('truetype');
}
@font-face {
  font-family: 'RooneySansMedium';
  src: local('RooneySanMedium'), url(../assets/fonts/RooneySans/RooneySansMedium.OTF) format('truetype');
}
@font-face {
  font-family: 'RooneySansBold';
  src: local('RooneySansBold'), url(../assets/fonts/RooneySans/RooneySansBold.OTF) format('truetype');
}
@font-face {
  font-family: 'RooneySansHeavy';
  src: local('RooneySansHeavy'), url(../assets/fonts/RooneySans/RooneySansHv.OTF) format('truetype');
}
.RooneySans {
  font-family: 'RooneySans';
}
.RooneySansMedium {
  font-family: 'RooneySansMedium';
}
.RooneySansBold {
  font-family: 'RooneySansBold';
}
.RooneySansHeavy {
  font-family: 'RooneySansHeavy';
}
.Roboto {
  font-family: 'Roboto';
}
*,
body {
  font-family: 'RooneySansBold';
  color: #324340;
}

.bg-hero {
  background: url('../assets/images/bg-hero.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-gallery {
  background-image: url('../assets/images/bg-gallery.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #FFFFFF;
}

.bg-contain {
  background-image: url('../assets/images/bg-gallery.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #FFFFFF;
}

.bg-gradient {
  background: linear-gradient(95deg, #9e62de 2.06%, #ecb7ee 52.18%, #ecb7ee 96.34%);
}

.bg-gradient-green {
  background: linear-gradient(180deg, #D3FFE9 0%, #D3FFE9 20%, #6FF3C9 48.8%, #B392F8 100%);
}

.ribbon-gradient {
  background: url('../assets/images/グラデ.png');
  background-repeat: no-repeat;
  background-position: center center;
}

.ribbon-green {
  background: url('../assets/images/パス 22.png');
  background-repeat: no-repeat;
  background-position: center center;
}

.input-admin {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #324340;
}

.badge-closed {
  position: absolute;
  padding-top: 45px;
  padding-bottom: 5px;
  top: -15%;
  left: -23%;
  transform: rotate(-35deg);
  background: white;
  width: 58%;
  text-align: center;
  color: #fff;
  background-color: red;
}
.badge-expired {
  position: absolute;
  padding-top: 3em;
  padding-bottom: 12px;
  top: -9%;
  left: -25%;
  transform: rotate(-35deg);
  background: white;
  width: 60%;
  text-align: center;
  color: #fff;
  background-color: red;
  font-size: 20px;
}

.swiper-slide{
  background-color: #F8F8F8!important;
}

.swiper-button-prev, .swiper-button-next{
  color: #61716E!important;
  font-size: 40px;
  font-weight: bold;
}

.swiper-pagination-bullet-active{
  background-color: #324340!important;
}

/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FFF; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999; 
}
.left,
.right {
  width: 10px;
  height: 10px;
  transition: 0.5s;
  box-shadow: -2px 2px 0 black;
  background: white;
}

.left {
  transform: rotate(45deg);
}

.right {
  transform: rotate(-135deg);
}

.gradient-idear {
  background: linear-gradient(90deg, #ea99fd 0%, #17ff9d 100%);
}

.MuiCircularProgress-colorPrimary {
  color: white !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  border: 0;
  margin-bottom: 6px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip::before {
  background-color: transparent;
}

.tooltip::after {
  display: none;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/** ----------- dropdown menu styling ----------- */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  /* display: none; */
  position: absolute;
  top: 60px;
  right: -55px;
  opacity: 1 !important;
  visibility: initial !important;
}

.dropdown-item {
  width: 220px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  background-color: #ffffff;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/** ----------- swal popup styling ----------- */
.swal2-container.swal2-center > .swal2-popup {
  border-radius: 20px;
}

.swal2-confirm.swal2-styled.swal2-default-outline,
.swal2-deny.swal2-styled,
.swal2-cancel.swal2-styled,
.swal2-cancel.swal2-styled.swal2-default-outline {
  background-color: #54f49e;
  font-family: 'RooneySans';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  border-radius: 60px;
  color: #fff;
  outline: 0;
  outline: none;
  border: none;
  width: 130px;
  box-shadow: none;
}

#text_input::placeholder {
  color: #55746E;
}

.border-white-important {
  border-color: white !important;
}
.swiper-button-next {
  @apply hidden !important;
  @apply xl:flex xl:right-[110px] !important;
  @apply 2xl:flex xl:right-[0px] !important;
  color: rgb(106, 113, 97) !important;
}
.swiper-button-prev {
  @apply hidden !important;
  @apply xl:flex xl:left-[110px] !important;
  @apply 2xl:flex xl:left-[0px] !important;
  color: rgba(97, 113, 110, 1) !important;
}
.swiper-slide {
  /* padding: 60px 0px !important; */
}
.swiper-pagination-bullet {
  width: 13px !important;
  height: 13px !important;
  background: #324340 !important;
  margin: 0px 13px !important;
}
.information-area::-webkit-scrollbar {
  display: none;
}